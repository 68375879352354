import './Menu.css';

const Menu = () => {
  return (
    <div className="menu">
      <ul className="menu-list">
        <li className="menu-item">
          <a className="menu-link" href="/">
            Picture
          </a>
        </li>

        <li className="menu-item">
          <a className="menu-link" href="/archive">
            Archive
          </a>
        </li>

        <li className="menu-item">
          <a className="menu-link" href="/privacy">
            Privacy policy
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Menu;
