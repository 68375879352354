import {
  getDatabase,
  ref,
  onValue,
  set,
  query,
  limitToLast,
  increment,
} from 'firebase/database';
import { getStorage, ref as myRef, uploadBytes } from "firebase/storage";
import { secretParent } from './config';

export const firebaseReadAllPictures = () => {
  const db = getDatabase();
  const collectionRef = query(
    ref(db, secretParent + '/pictures'),
    limitToLast(100),
  );

  const resolveReject = function (ref) {
    const resolveRejectPromise = new Promise(function (resolve, reject) {
      onValue(collectionRef, snapshot => {
        const result = snapshot.val();
        resolve(result);
      });
    });
    return resolveRejectPromise;
  };
  return resolveReject(collectionRef);
};

export const firebaseReadLastPicture = () => {
  const db = getDatabase();
  const collectionRef = query(
    ref(db, secretParent + '/pictures'),
    limitToLast(1),
  );
  const resolveReject = function (ref) {
    const resolveRejectPromise = new Promise(function (resolve, reject) {
      onValue(collectionRef, snapshot => {
        const result = snapshot.val();
        resolve(result);
      });
    });
    return resolveRejectPromise;
  };
  return resolveReject(collectionRef);
};

export const firebasePictureCount = () => {
  const db = getDatabase();
  const collectionRef = ref(db, secretParent + '/pictures/');
  const resolveReject = function (ref) {
    const resolveRejectPromise = new Promise(function (resolve, reject) {
      onValue(collectionRef, snapshot => {
        const result = snapshot.size;
        resolve(result);
      });
    });
    return resolveRejectPromise;
  };
  return resolveReject(collectionRef);
};

export const firebaseLikePlus = (timestamp, isPlus) => {
  const db = getDatabase();
  const collectionRef = ref(
    db,
    secretParent + '/pictures/' + timestamp + '/likes',
  );
  set(collectionRef, isPlus ? increment(1) : increment(-1));
};

export const firebaseAddComment = (timestamp, commentObj) => {
  const db = getDatabase();
  set(
    ref(
      db,
      secretParent + '/pictures/' + timestamp + '/comments/' + commentObj.date,
    ),
    commentObj,
  );
};

export const firebasePictureAddEndTime = (timestamp, endtime) => {
  const db = getDatabase();
  const collectionRef = ref(
    db,
    secretParent + '/pictures/' + timestamp + '/end',
  );
  set(collectionRef, endtime);
};

export const firebaseReadComments = timestamp => {
  const db = getDatabase();
  const collectionRef = ref(
    db,
    secretParent + '/pictures/' + timestamp + '/comments/',
  );
  const resolveReject = function (ref) {
    const resolveRejectPromise = new Promise(function (resolve, reject) {
      onValue(collectionRef, snapshot => {
        const result = snapshot.val();
        resolve(result);
      });
    });
    return resolveRejectPromise;
  };
  return resolveReject(collectionRef);
};

  // funkce pro nahrání souboru
export const uploadFile = (file) => {
  const storage = getStorage();
  // vytvoření reference s náhodným názvem souboru
  const fileRef = myRef(storage, Date.now());

  // nahrání souboru do Storage
  return uploadBytes(fileRef, file)
    .then((snapshot) => {
      console.log('Soubor byl úspěšně nahrán do Storage:', snapshot);
      return snapshot.ref.getDownloadURL();
    })
    .catch((error) => {
      console.error('Chyba při nahrávání souboru do Storage:', error);
    });
};

export const firebaseNewPicture = (
  imageOriginal,
  picture,
  link,
  message,
  likes,
  comments,
  timestamp,
  end,
) => {
  const db = getDatabase();
  set(ref(db, secretParent + '/pictures/' + timestamp), {
    picture: 'storage', // Původní picture hodnota vyměněna za string
    link: link,
    message: message,
    likes: 0,
    comments: {},
    timestamp: timestamp,
    end: '',
  });
  const storage = getStorage();
  // funkce pro nahrání souboru
const uploadFile = (file) => {
  // vytvoření reference s náhodným názvem souboru
  const fileRef = myRef(storage, '/' + timestamp);

  // nahrání souboru do Storage
  return uploadBytes(fileRef, file)
    .then((snapshot) => {""
      console.log('Soubor byl úspěšně nahrán do Storage:', snapshot);
      return snapshot.fileRef.getDownloadURL();
    })
    .catch((error) => {
      console.error('Chyba při nahrávání souboru do Storage:', error);
    });
};
uploadFile(imageOriginal)
};

export const firebaseAddPushNotificationReceiver = token => {
  const db = getDatabase();
  set(ref(db, secretParent + '/nofitication-receivers/' + token), '');
};

export const firebaseReadPushNotificationReceiver = () => {
  const db = getDatabase();
  const collectionRef = query(
    ref(db, secretParent + '/nofitication-receivers'),
  );

  const resolveReject = function (ref) {
    const resolveRejectPromise = new Promise(function (resolve, reject) {
      onValue(collectionRef, snapshot => {
        const result = snapshot.val();
        resolve(result);
      });
    });
    return resolveRejectPromise;
  };
  return resolveReject(collectionRef);
};
