import './LoadingSpinner.css';

const LoadingSpinner = () => {
  return (
    <div id="outer">
      <div className="loadingspinner"></div>
    </div>
  );
};

export default LoadingSpinner;
