import React, { useState, useEffect } from 'react';

import './TimestampDiff.css';

const TimestampDiff = (props: any) => {
  const [time, setTime] = useState(Date.now());

  let difference = Date.now() - props.timestamp;

  if (props.end) {
    difference = props.end - props.timestamp;
    console.log(time);
  }

  const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
  difference -= daysDifference * 1000 * 60 * 60 * 24;

  const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
  difference -= hoursDifference * 1000 * 60 * 60;

  const minutesDifference = Math.floor(difference / 1000 / 60);
  difference -= minutesDifference * 1000 * 60;

  const secondsDifference = Math.floor(difference / 1000);

  useEffect(() => {
    if (props.end) {
      return;
    }
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="image-time">
      {daysDifference +
        ' days ' +
        hoursDifference +
        ' hours ' +
        minutesDifference +
        ' minutes ' +
        secondsDifference +
        ' seconds '}
    </div>
  );
};

export default TimestampDiff;
