import React, { useState, useEffect } from 'react';
import { firebaseReadComments } from '../firebase/firebaseCalls';

import './CommentList.css';

const CommentList = (props: any) => {
  const [comments, setComments] = useState<{}>({});
  const [fetching, setFetching] = useState(true);

  const formattedDate = (date: any) => {
    var result = '';
    date = new Date(parseInt(date));
    result +=
      date.getDate() +
      '.' +
      (date.getMonth() + 1) +
      '.' +
      date.getFullYear() +
      ' ' +
      ('0' + date.getHours()).slice(-2) +
      ':' +
      ('0' + date.getMinutes()).slice(-2) +
      ':' +
      ('0' + date.getSeconds()).slice(-2);
    return result;
  };

  const getComments = async () => {
    try {
      const fbData = await firebaseReadComments(props.timestamp);
      if (typeof fbData === 'object' && fbData !== null) {
        const stringFbData = JSON.stringify(fbData);
        const jsonFbData = JSON.parse(stringFbData);
        setComments(jsonFbData);
        setFetching(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  interface Comment {
    date: string;
    name: string;
    message: string;
  }

  useEffect(() => {
    getComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.timestamp, props.lastUpdate, fetching]);

  return (
    <div className="comments">
      {comments &&
        Object.entries(comments)
          .reverse()
          .map(([key, val], i) => (
            <div className="comment" key={i}>
              <div className="comment-meta">
                <div className="comment-name">{(val as Comment).name}</div>
                <div className="comment-date">
                  {formattedDate((val as Comment).date)}
                </div>
              </div>
              <div className="comment-message">{(val as Comment).message}</div>
              <b className="hr2"></b>
            </div>
          ))}
    </div>
  );
};

export default CommentList;
