import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { set, get } from 'idb-keyval';
import {
  firebaseLikePlus,
  firebaseReadLastPicture,
} from '../firebase/firebaseCalls';

import './LikeButton.css';

const LikeButton = (props: any) => {
  const [liked, setLiked] = useState(false);
  const [likes, setLikes] = useState(props.data.likes);

  const getPictureData = async () => {
    try {
      const fbData = await firebaseReadLastPicture();
      if (typeof fbData === 'object' && fbData !== null) {
        const stringFbData = JSON.stringify(fbData);
        const jsonFbData = JSON.parse(stringFbData);
        const recordTimestamp = Object.keys(jsonFbData)[0];
        const recordLikes = jsonFbData[recordTimestamp].likes;

        setLikes(recordLikes);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setIDBKeyval = async (currentPic: string) => {
    await set('picofaday-liked', currentPic);
  };

  const getIDBKeyval = async () => {
    const whatDoWeHave = await get('picofaday-liked');
    if (whatDoWeHave === props.data.timestamp) {
      setLiked(true);
    }
  };

  useEffect(() => {
    getPictureData();
    getIDBKeyval();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [likes]);

  return (
    <div
      onClick={() => {
        setLiked(!liked);
        setLikes(liked ? likes - 1 : likes + 1);
        firebaseLikePlus(props.data.timestamp, liked ? false : true);
        setIDBKeyval(liked ? '' : props.data.timestamp);
      }}
      className={cn('like-button-wrapper', {
        liked,
      })}>
      <div className="like-button">
        {liked ? <span>&#9829;</span> : <span>&#9825;</span>} {likes}
      </div>
    </div>
  );
};

export default LikeButton;
