import React, { useState, useEffect } from 'react';
import './App.css';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import ReactGA from 'react-ga';
import { firebaseStorageUrl, parseJwt } from './firebase/config';

import {
  getAuth,
  signInWithPopup,
  signOut,
  GoogleAuthProvider,
  FacebookAuthProvider,
  onAuthStateChanged,
} from 'firebase/auth';
import {
  getTokens,
  onMessageListener,
  sendPushNotification,
} from './firebase/config';
import {
  firebaseNewPicture,
  firebaseReadLastPicture,
} from './firebase/firebaseCalls';
import {
  ref,
  list,
  listAll,
  getStorage,
  getDownloadURL,
} from 'firebase/storage';

import { useForm } from 'react-hook-form';
import LikeButton from './LikeButton/LikeButton';
import TimestampDiff from './TimestampDiff/TimestampDiff';
import CommentForm from './CommentForm/CommentForm';
import LoadingSpinner from './LoadingSpinner/LoadingSpinner';
import {
  firebasePictureCount,
  firebasePictureAddEndTime,
} from './firebase/firebaseCalls';

declare global {
  interface Window {
    handleCredentialResponse?: any;
  }
}

function App() {
  window.handleCredentialResponse = handleCredentialResponse;
  const [loggedUserName, setLoggedUserName] = useState('');
  const [loggedUserUID, setLoggedUserUID] = useState('');
  const [imageZoom, setImageZoom] = useState(false);
  const [counter, setCounter] = useState(0);
  const [imageBase64, setImageBase64] = useState<undefined | string>(undefined);
  const [imageOriginal, setImageOriginal] = useState<File | undefined>(
    undefined,
  );
  const [imageValid, setImageValid] = useState(false);
  const [invalidImageMessage, setInvalidImageMessage] = useState('');
  const [data, setData] = useState<{
    image: undefined | string;
    link: null | string;
    message: null | string;
    likes: number;
    comments: string[];
    timestamp: string;
    end: string;
  }>({
    image: '',
    link: '',
    message: '',
    likes: 0,
    comments: [],
    timestamp: '',
    end: '',
  });
  const { register, handleSubmit, reset } = useForm();

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [isTokenFound, setTokenFound] = useState(false);

  onMessageListener()
    .then(payload => {
      setShow(true);
      console.log(notification);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      // console.log(payload);
    })
    .catch(err => console.log('failed: ', err));

  const auth = getAuth();

  const logout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        setLoggedUserName('');
      })
      .catch(error => {
        // An error happened.
      });
  };

  const responseGoogle = (response: any) => {
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/userinfo.profile');

    onAuthStateChanged(auth, user => {
      if (response.isSignedIn()) {
        // User is already signed in
        if (user !== null) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          const userdisplayName = user.displayName ? user.displayName : '';
          setLoggedUserName(userdisplayName);
          const userUid = user.uid ? user.uid : '';
          setLoggedUserUID(userUid);
          // ...
        } else {
          // User is signed out
          signInWithPopup(auth, provider)
            .then(result => {
              // This gives you a Google Access Token. You can use it to access the Google API.
              // const credential =
              //   GoogleAuthProvider.credentialFromResult(result);
              // const token = credential?.accessToken;
              // The signed-in user info.
              const user = result.user;
              const userdisplayName = user.displayName ? user.displayName : '';
              setLoggedUserName(userdisplayName);
              // ...
            })
            .catch(error => {
              // Handle Errors here.
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const errorCode = error.code;
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const errorMessage = error.message;
              // The email of the user's account used.
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const email = error.email;
              // The AuthCredential type that was used.
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const credential = GoogleAuthProvider.credentialFromError(error);
            });
        }
      } else {
        // User is signed out
      }
    });
  };

  function handleCredentialResponse(response: any) {
    const userInfo = parseJwt(response.credential);
    responseGoogle(response.credential);
    setLoggedUserName(userInfo.name);
    setLoggedUserUID(userInfo.sub);
  }
  const responseFacebook = (response: any) => {
    const provider = new FacebookAuthProvider();
    provider.addScope('public_profile');

    const auth = getAuth();

    onAuthStateChanged(auth, user => {
      if (response.accessToken) {
        // User is already signed in
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          const userdisplayName = user.displayName ? user.displayName : '';
          setLoggedUserName(userdisplayName);
          // ...
        } else if (!loggedUserName) {
          signInWithPopup(auth, provider)
            .then(result => {
              // The signed-in user info.
              // const user = result.user;
              // This gives you a Facebook Access Token. You can use it to access the Facebook API.
              // const credential =
              //   FacebookAuthProvider.credentialFromResult(result);
              // const accessToken = credential?.accessToken;
              // ...
            })
            .catch(error => {
              // Handle Errors here.
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const errorCode = error.code;
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const errorMessage = error.message;
              // The email of the user's account used.
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const email = error.email;
              // The AuthCredential type that was used.
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const credential =
                FacebookAuthProvider.credentialFromError(error);
              // ...
            });
        }
      } else {
        // User is signed out
      }
    });
  };

  const onSubmit = (formData: any) => {
    setData({
      image: imageBase64,
      link: formData.link,
      message: formData.message,
      likes: 0,
      comments: [],
      timestamp: Date.now().toString(),
      end: '',
    });
    data.timestamp &&
      firebasePictureAddEndTime(data.timestamp, Date.now().toString());
    firebaseNewPicture(
      imageOriginal,
      imageBase64,
      formData.link,
      formData.message,
      0,
      {},
      Date.now().toString(),
      '',
    );
    reset({ image: '', message: '', link: '' });
    sendPushNotification();
  };

  const validateImage = (file: any) => {
    if (file.size > 1024 * 1024 * 50) {
      setImageValid(false);
      setInvalidImageMessage('Error: Max 50 Mb');
    } else if (!['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
      setImageValid(false);
      setInvalidImageMessage('Error: Only PNG, JPEG and GIF');
    } else {
      setImageValid(true);
    }
  };

  const toBase64 = (file: any) => {
    validateImage(file);
    // encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = () => {
      // use a regex to remove data url part
      const base64String = reader.result;
      setImageBase64(base64String ? base64String?.toString() : undefined);
    };
    reader.readAsDataURL(file);
  };

  const zoomHandler = () => {
    setImageZoom(state => !state);
  };

  const getPictureData = async () => {
    try {
      const fbData = await firebaseReadLastPicture();
      if (typeof fbData === 'object' && fbData !== null) {
        const stringFbData = JSON.stringify(fbData);
        const jsonFbData = JSON.parse(stringFbData);
        const recordTimestamp = Object.keys(jsonFbData)[0];
        let recordPicture = jsonFbData[recordTimestamp].picture;
        const recordMessage = jsonFbData[recordTimestamp].message;
        const recordLink = jsonFbData[recordTimestamp].link;
        const recordLikes = jsonFbData[recordTimestamp].likes;
        const recordComments = jsonFbData[recordTimestamp].comments;
        const recordEnd = jsonFbData[recordTimestamp].end;

        if (recordPicture === 'storage') {
          recordPicture = firebaseStorageUrl + recordTimestamp + '?alt=media';
        }

        setData({
          image: recordPicture,
          link: recordLink,
          message: recordMessage,
          likes: recordLikes,
          comments: recordComments,
          timestamp: recordTimestamp,
          end: recordEnd,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPicturesCount = async () => {
    try {
      const fbData = await firebasePictureCount();
      if (typeof fbData === 'number') {
        setCounter(fbData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  ReactGA.initialize('UA-220742755-1');
  ReactGA.pageview(window.location.pathname + window.location.search);

  const origin = 'https://pictureofaday.com';
  const redirectUri =
    typeof window !== 'undefined' ? origin + window.location.pathname : origin;

  useEffect(() => {
    getPictureData();
    getPicturesCount();
    getTokens(setTokenFound);
  }, [
    loggedUserName,
    imageZoom,
    data.image,
    data.timestamp,
    isTokenFound,
    show,
  ]);

  return (
    <div className="App">
      {/* 
      FOREGROUND NOTIFICATIONS
      <div onClick={() => setShow(false)} style={{
          position: 'absolute',
          top: 20,
          left: 20,
          minWidth: 200
        }}>
          <div>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto">{notification.title}</strong>
            <small>just now</small>
          </div>
          <div>{notification.body}</div>
        </div>
      <header className="App-header">
        {isTokenFound && <h1> Notification permission enabled 👍🏻 </h1>}
        {!isTokenFound && <h1> Need notification permission ❗️ </h1>}
        <button onClick={() => setShow(true)}>Show Toast</button>
      </header> */}
      <header className="App-header">
        <h1 className="App-headline">Picture of a day</h1>
        <div className="images-counter">#{counter}</div>
      </header>
      <section className="App-body ">
        <div className="image-section">
          {data.image ? (
            <img
              src={
                data.image
                  ? data.image
                  : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8/5+hHgAHggJ/PchI7wAAAABJRU5ErkJggg=='
              }
              className={`App-logo ${imageZoom ? 'zoomed' : ''}`}
              alt={data.message ? data.message : 'Picture of a day'}
              onClick={zoomHandler}
            />
          ) : (
            <LoadingSpinner />
          )}
          {data.timestamp && <TimestampDiff timestamp={data.timestamp} />}
          {data.timestamp && <LikeButton data={data} />}
          <h2>
            {data.link ? (
              <a
                className="App-link"
                href={data.link ? data.link : '#'}
                target="_blank"
                rel="noopener noreferrer">
                {data.message ? data.message : ''}
              </a>
            ) : (
              <span>{data.message ? data.message : ''}</span>
            )}
          </h2>
        </div>

        <b className="hr"></b>

        <div className="user-content">
          {loggedUserName ? (
            <div className="user-section">
              <span className="user-name">{loggedUserName}</span>
              <GoogleLogout
                clientId="57657928315-b1h2iondr8smj46gr5eqnlgj6scpsgju.apps.googleusercontent.com"
                buttonText="Logout"
                onLogoutSuccess={logout}
                render={renderProps => (
                  <button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    className="google-logout">
                    Logout
                  </button>
                )}
              />
              <br />
              <h5 className="upload-headline">Upload new picture</h5>
              <br />
              <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-fields">
                  <input {...register('message')} placeholder="Your message" />
                  <input {...register('link')} placeholder="Link URL" />
                  <input
                    {...register('image')}
                    type="file"
                    className={`custom-file-input ${!imageValid && 'invalid'}`}
                    onChange={event => {
                      toBase64(event!.target!.files![0]);
                      setImageOriginal(event!.target!.files![0]);
                    }}
                    required
                  />
                </div>
                {!imageValid ? (
                  <>
                    <input type="submit" disabled />
                    <span className="error-message">{invalidImageMessage}</span>
                  </>
                ) : (
                  <input type="submit" />
                )}
              </form>
              <b className="hr"></b>
              {/* {loggedUserName} - {loggedUserUID} - {data.timestamp} */}
              <CommentForm
                name={loggedUserName}
                uid={loggedUserUID}
                timestamp={data.timestamp}
              />
            </div>
          ) : (
            <>
              <h5 className="user-manual-headline">Your turn</h5>
              <p className="user-manual">
                Login to upload new picture or comment.
              </p>
              <div className="login-buttons">
                {/* <div
                  id="g_id_onload"
                  data-client_id="57657928315-b1h2iondr8smj46gr5eqnlgj6scpsgju.apps.googleusercontent.com"
                  data-context="signup"
                  data-ux_mode="popup"
                  data-callback="handleCredentialResponse"
                  data-auto_select="true"></div>

                <div
                  className="g_id_signin"
                  data-type="standard"
                  data-shape="rectangular"
                  data-theme="outline"
                  data-text="signin_with"
                  data-size="large"
                  data-logo_alignment="left"></div> */}

                <GoogleLogin
                  clientId="57657928315-b1h2iondr8smj46gr5eqnlgj6scpsgju.apps.googleusercontent.com"
                  buttonText="Login"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={'single_host_origin'}
                  isSignedIn={typeof window !== 'undefined' ? true : false}
                />
                <FacebookLogin
                  appId="1674833449528170"
                  autoLoad={false}
                  fields="name"
                  textButton="Login"
                  callback={responseFacebook}
                  cssClass="facebook-button"
                  icon="fa-facebook"
                  isMobile={typeof window !== 'undefined' ? true : false}
                  redirectUri={redirectUri}
                />
              </div>
              <b className="hr"></b>
              <CommentForm
                name={loggedUserName}
                uid={loggedUserUID}
                timestamp={data.timestamp}
              />
            </>
          )}
        </div>
      </section>
    </div>
  );
}

export default App;
