import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { firebaseAddComment } from '../firebase/firebaseCalls';
import CommentList from '../CommentList/CommentList';
import './CommentForm.css';

const CommentForm = (props: any) => {
  const { register, handleSubmit, reset } = useForm();
  const [lastComment, setLastComment] = useState('');

  const [data, setData] = useState<{
    name: undefined | string;
    uid: undefined | string;
    message: null | string;
    timestamp: string;
  }>({
    name: '',
    uid: '',
    message: '',
    timestamp: '',
  });

  const onSubmit = (data: any) => {
    setData({
      name: props.name,
      uid: props.uid,
      message: data.message,
      timestamp: Date.now().toString(),
    });
    firebaseAddComment(props.timestamp, {
      name: props.name,
      uid: props.uid,
      message: data.message,
      date: Date.now().toString(),
    });
    setLastComment(Date.now().toString());
    reset({ message: '' });
  };

  useEffect(() => {}, [data.message, lastComment, props.timestamp]);

  return (
    <>
      {props.name && <h5 className="comments-headline">Add your comment</h5>}
      <br />
      {props.name && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-fields">
            <input
              {...register('message')}
              className="comment-input"
              placeholder="Your comment"
              required
            />
          </div>
          <input type="submit" />
        </form>
      )}
      <CommentList lastUpdate={lastComment} timestamp={props.timestamp} />
    </>
  );
};

export default CommentForm;
