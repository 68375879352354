import React, { useState, useEffect } from 'react';
import './Archive.css';
import './firebase/config';
import { firebaseReadAllPictures } from './firebase/firebaseCalls';
import TimestampDiff from './TimestampDiff/TimestampDiff';
import { firebaseStorageUrl } from './firebase/config';

function Archive() {
  const [allPictures, setAllPictures] = useState();
  const [imageZoom, setImageZoom] = useState(false);
  const [zoomedImage, setZoomedImage] = useState(0);

  const zoomHandler = (element: number) => {
    setZoomedImage(element);
    setImageZoom(state => !state);
  };

  const getAllPicturesData = async () => {
    try {
      const fbData = await firebaseReadAllPictures();
      if (typeof fbData === 'object') {
        const stringFbData = JSON.stringify(fbData);
        const jsonFbData = JSON.parse(stringFbData);
        setAllPictures(jsonFbData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllPicturesData();
  }, []);

  interface Picture {
    picture: string;
    likes: string;
    timestamp: string;
    end: string;
  }

  return (
    <div className="App">
      <header className="App-header">
        <h1>Archive</h1>
      </header>
      <section className="App-body ">
        {/* {data.image && ( */}
        <div className="image-section">
          {allPictures &&
            Object.entries(allPictures)
              .reverse()
              .slice(1)
              .map(([key, val], i) => (
                <div key={i}>
                  <div className="archive-image-wrapper">
                    <img
                      className={`archive-image ${
                        imageZoom && zoomedImage === i ? 'zoomed' : ''
                      }`}
                      src={(val as Picture).picture && (val as Picture).picture !== 'storage' ? (val as Picture).picture : firebaseStorageUrl + (val as Picture).timestamp + '?alt=media'}
                      alt={(val as Picture).likes && (val as Picture).likes}
                      onClick={() => zoomHandler(i)}
                    />
                    <div className="archive-image-info">
                      <div className="archive-image-time">
                        {(val as Picture).timestamp && (val as Picture).end && (
                          <TimestampDiff
                            timestamp={(val as Picture).timestamp}
                            end={(val as Picture).end}
                          />
                        )}
                      </div>
                      <div className="archive-image-likes">
                        <span>&#9825;</span> {(val as Picture).likes}
                      </div>
                    </div>
                  </div>
                  <b className="hr2"></b>
                </div>
              ))}
        </div>
      </section>
    </div>
  );
}

export default Archive;
